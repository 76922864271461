<div class="contract-container">
  <!-- Título y subtítulo -->
  <app-page-header
    [title]="'Mis contratos'"
    [subtitle]="'Visualiza tus boletas'"
  ></app-page-header>

  <!-- Botones, combo y barra de búsqueda -->
  <app-page-acciones
    [hasButton]="false"
    [comboOptions]="monthOptions"
    [comboSelected]="currentMonth"
    (comboSelectedChange)="onMonthChange($event)"
    [searchQuery]="searchTerm"
    (searchQueryChange)="onSearch($event)"
  >
  </app-page-acciones>

  <!-- Tabs y tabla dinámica -->
  <app-page-container
    [displayedColumns]="displayedColumns"
    [dataSource]="historyContract"
    [showPreviewCard]="false"
    [isLoading]="isLoadingHistory"
    [customTemplates]="{
      companyName: companyNameTemplate,
      jobPositionName: jobPositionNameTemplate,
      salary: salaryTemplate,
      startDate: startDateTemplate,
      endDate: endDateTemplate
    }"
    (rowSelected)="onRowSelected($event)"
    [hasCheckbox]="false"
  >
    <ng-template #companyNameTemplate let-row>
      <span class="truncate-text">{{ row.companyName | capitalize }}</span>
    </ng-template>
    <ng-template #jobPositionNameTemplate let-row>
      <span class="truncate-text">{{ row.jobPositionName | capitalize }}</span>
    </ng-template>
    <ng-template #salaryTemplate let-row> S/. {{ row.salary }} </ng-template>
    <ng-template #startDateTemplate let-row>
      {{ formatDate(row.startDate) }}
    </ng-template>
    <ng-template #endDateTemplate let-row>
      {{ formatDate(row.endDate) }}
    </ng-template>
  </app-page-container>
</div>
