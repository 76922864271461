import { ChangeDetectorRef, Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IComboBase } from '../interfaces/base/base.interface';
import { monthOptions } from '../utils/helpers';
import { ContractService } from './services/contract.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
  providers: [DialogService],
})
export class ContractComponent {
    userData: any = null;

  selectedTabKey: number = 1;
  detailContract: any = null;
  historyContract: any[] = [];
  isLoading: boolean = false;
  isLoadingHistory: boolean = false;
  selectedDocument: SafeResourceUrl | null = null;
  selectedContractIndex: number | null = null;
  isSelected: boolean[] = [];
  noDocumentMessage: string | null = null;

  // Opciones del combo
  monthOptions: IComboBase[] = [{ key: '00', text: 'Todos' }, ...monthOptions];
  currentMonth: IComboBase | null = null;

  // Término de búsqueda
  searchTerm = '';

  // Mes seleccionado
  selectedMonth: IComboBase | null = null;

  // dataSource: any[] = [];
  displayedColumns = [
    { key: 'companyName', header: 'Empresa' },
    { key: 'jobPositionName', header: 'Cargo' },
    { key: 'salary', header: 'Sueldo' },
    { key: 'startDateContract', header: 'Fecha Inicio' },
    { key: 'endDateContract', header: 'Fecha Fin' },
    { key: 'typeContractName', header: 'Tipo' },
    { key: 'stateContract', header: 'Status' },
  ];

  // Fila seleccionada
  selectedRowId: number | null = null;

  constructor(
    private contractService: ContractService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }
    
      this.isLoadingHistory = true;

    //   this.contractService
    //     .getContractDetailByEmployeeId(this.userData.employeeId)
    //     .subscribe({
    //       next: (data) => {
    //         this.detailContract = data;
    //         this.detailContract.startDateEmployee = this.convertToDate(
    //           this.detailContract.startDateEmployee
    //         );
    //         if (this.detailContract?.contracts) {
    //           this.detailContract.contracts.forEach((contract: any) => {
    //             contract.startDateContract = this.convertToDate(
    //               contract.startDateContract
    //             );
    //           });

    //           this.isSelected = new Array(
    //             this.detailContract.contracts.length
    //           ).fill(false);
    //         }
    //         this.isLoadingHistory = false;
    //       },
    //       error: (err) => {
    //         console.error(
    //           'Error al obtener la lista de contratos por empleado:',
    //           err
    //         );
    //         this.isLoadingHistory = false;
    //       },
    //     });

      this.filterData(this.userData.employeeId);

  }

  private filterData(employeeId: number): void {
    this.isLoadingHistory = true;

    this.contractService
      .getContractHistoryByEmployeeId(employeeId)
      .subscribe({
        next: (data) => {
          this.historyContract = data;
          this.isLoadingHistory = false;
        },
        error: (err) => {
          console.error('Error al obtener la historia de contratos:', err);
          this.isLoadingHistory = false;
        },
      });
  }

  onContractSelect(index: number, fileContract: string | null): void {
    if (!fileContract) {
      this.selectedDocument = null;
      this.isSelected.fill(false);
      this.isSelected[index] = true;
      this.noDocumentMessage =
        'No cuenta con documento debido a que no ha sido renovado.';
      return;
    } else if (fileContract === '') {
      this.selectedDocument = null;
      this.noDocumentMessage = 'No se ha adjuntado el contrato aún.';
    } else {
      this.selectedDocument =
        this.sanitizer.bypassSecurityTrustResourceUrl(fileContract);
      this.noDocumentMessage = null;
    }

    this.noDocumentMessage = null;
    this.isSelected.fill(false);
    this.isSelected[index] = true;
    this.selectedDocument =
      this.sanitizer.bypassSecurityTrustResourceUrl(fileContract);
    this.isLoading = false;
  }

  getStatusClass(status: number): string {
    switch (status) {
      case 1: // No renovado
        return 'status-not-renewed';
      case 2: // Enviado
        return 'status-sent';
      case 3: // Firmado
        return 'status-signed';
      case 4: // Pendiente
        return 'status-pending';
      default:
        return 'status-default';
    }
  }

  onRowSelected(row: any): void {
    this.selectedRowId = row.contractId;
  }

  onDocumentLoad(): void {
    this.isLoading = false;
  }

  onDocumentError(): void {
    this.isLoading = false;
    this.selectedDocument = null;
  }

  convertToDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/');
    return new Date(+year, +month - 1, +day);
  }

  formatDate(dateString: string): string {
    if (!dateString) return '';

    // Mapeo de nombres de meses en inglés a números
    const monthsMap: { [key: string]: string } = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };

    // Extraer los datos de la fecha usando una expresión regular
    const dateParts = dateString.match(/(\w+)\s(\d+)\sdel\s(\d+)/);
    if (!dateParts) return dateString;

    const month = monthsMap[dateParts[1]];
    const day = dateParts[2].padStart(2, '0');
    const year = dateParts[3].slice(-2);

    return `${day}/${month}/${year}`;
  }

  onMonthChange(selected: IComboBase): void {
    this.selectedMonth = selected;
    // this.filterData();
  }

  onSearch(query: string): void {
    this.searchTerm = query;
    // this.filterData();
  }

  onNewRenewalContract(): void {
    const employeeId = this.route.snapshot.paramMap.get('id');
  
    this.router.navigate(['/dashboard/employee/register-contract'], {     
      state: { employeeId: employeeId }
    });

    console.log("employeeId DETALLE:" + employeeId);
    
  }
  

  goBack() {
    this.router.navigate(['/dashboard/employee/contract']);
  }
}
