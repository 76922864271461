import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ContractComponent } from './contract.component';
import { ContractRoutingModule } from './contract-routing.module';


@NgModule({
    declarations: [
        ContractComponent,
    ],
    imports: [
        ContractRoutingModule,
        CommonModule,
        RouterModule,
        SharedModule
    ]
})
export class ContractModule {}
