import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  private apiUrlContract = `${environment.apiUrl}/contracts`;

  constructor(private http: HttpClient) {}

  getContractByContractId(idContract: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrlContract}/${idContract}`);
  }

  updateChangeStateContract(
    idContract: number,
    data: FormData
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrlContract}/changeStateContract/${idContract}`,
      data
    );
  }

  updateContract(id: number, data: FormData): Observable<any> {
    return this.http.put<any>(`${this.apiUrlContract}/${id}`, data);
  }

  getContractDetailByEmployeeId(employeeId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrlContract}/detail/${employeeId}`);
  }

  getContractHistoryByEmployeeId(employeeId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrlContract}/history/${employeeId}`);
  }
}
